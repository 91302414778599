import React from "react";

function JsonResponse(props) {
  let responseObject = [];
  try {
    responseObject = JSON.parse(props.jsonText);
  } catch (e) {}
  return (
    <ol>
      {responseObject.map((item) => {
        return (
          <li>
            <strong>
              <a target="_blank" rel="noreferrer" href={item.imdbUrl}>
                {item.name || ""}
              </a>
            </strong>{" "}
            {item.imdbScore && item.rottenTomatoesScore
              ? `(IMDB ${item.imdbScore}, RT ${item.rottenTomatoesScore}%) `
              : ""}
            - {item.description || ""}
          </li>
        );
      })}
    </ol>
  );
}

function ChatMessage(props) {
  function chatTextElement(text) {
    const parts = text.split(/(<json>|<\/json>)/);
    const innerElements = parts.map((part, index) => {
      if (part === "<json>") {
        return null;
      } else if (part === "</json>") {
        return null;
      } else if (parts[index - 1] === "<json>") {
        return <JsonResponse key={`json-response-${index}`} jsonText={part} />;
      } else {
        return <span key={`plain-text-${index}`}>{part}</span>;
      }
    });

    return <div className="chatText">{innerElements}</div>;
  }

  return (
    <div
      key={props.index}
      className={`message ${props.message.fromUser ? "right" : "left"}`}
    >
      {chatTextElement(props.message.text)}
    </div>
  );
}

export default ChatMessage;
