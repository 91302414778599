import React, { useState, useEffect, useRef } from "react";
import Chat from "./Chat";

function App() {
  return (
    <div className="App">
      <div className="header">
        <h1>Your streaming concierge</h1>
      </div>
      <Chat></Chat>
    </div>
  );
}

export default App;
