import React, { useState, useEffect, useRef } from "react";
import { initializeApp } from "firebase/app";
import {
  getDatabase,
  ref,
  onChildAdded,
  push,
  get,
  child,
  off,
  set,
} from "firebase/database";
import { getAuth, onAuthStateChanged, signInAnonymously } from "firebase/auth";
import TextareaAutosize from "react-textarea-autosize";
import ChatMessage from "./ChatMessage";

const firebaseConfig = {
  apiKey: "AIzaSyBfz_fg1_D5teXPJ3ovsfMURyhRiYvdipw",
  authDomain: "streamline-tv-app.firebaseapp.com",
  databaseURL: "https://streamline-tv-app-default-rtdb.firebaseio.com",
  projectId: "streamline-tv-app",
  storageBucket: "streamline-tv-app.appspot.com",
  messagingSenderId: "588515000465",
  appId: "1:588515000465:web:57f55a78661f014602bf30",
  measurementId: "G-6MQVGJW8EW",
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

function Chat() {
  const [messages, setMessages] = useState([]);
  const [messagesRef, setMessageRef] = useState(null);
  const [userRef, setUserRef] = useState(null);
  const [unsubscribeToLastChat, setUnsubscribeToLastChat] = useState(null);
  const [value, setValue] = useState("");
  const [loggedInUserId, setLoggedInUserId] = useState("");
  const messagesEndRef = useRef(null);

  function startChatListener(userId, chatId) {
    setMessages([]);
    unsubscribeToLastChat?.();

    const firebaseRef = ref(
      database,
      `users/list/${userId}/chats/list/${chatId}/messages/list`
    );
    setMessageRef(firebaseRef);

    const handleChildAdded = (snapshot) => {
      setMessages((prevMessages) => [...prevMessages, snapshot.val()]);
    };
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        from: "server",
        timestamp: Date.now(),
        text: "Tell me about what kind of movies and TV you like to watch, what you like about them, and the kind of mood you're in. Be as detailed as you'd like!",
      },
    ]);

    const unsubscribeFunc = onChildAdded(firebaseRef, handleChildAdded);

    setUnsubscribeToLastChat(() => unsubscribeFunc);
  }

  const listenToLoginAlready = useRef(false);
  useEffect(() => {
    if (listenToLoginAlready.current) return;
    listenToLoginAlready.current = true;

    const auth = getAuth(app);

    // let unsubscribeMessages = null;
    const onUserLoggedIn = async (userId) => {
      setLoggedInUserId(userId);

      const firebaseRef = ref(database, `users/list/${userId}`);
      setUserRef(firebaseRef);

      const lastChatId = (
        await get(child(firebaseRef, "chats/lastChatId"))
      ).val();
      startChatListener(userId, lastChatId || "-");
    };

    const signInAnonymouslyIfNeeded = () => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is already logged in, call the callback with their user ID
          onUserLoggedIn(user.uid);
        } else {
          // No user is signed in, sign in the user anonymously
          signInAnonymously(auth)
            .then((result) => {
              // onAuthStateChanged will fire again and take care of this
            })
            .catch((error) => {
              console.error("Error signing in anonymously:", error);
            });
        }
      });
    };

    signInAnonymouslyIfNeeded();

    return () => {
      // unsubscribeMessages?.();
    };
  }, []);

  useEffect(() => {
    // NOTE: Need the timeout in order for it to properly react to value
    // changing (otherwise I think there's an order of operation race condition)
    setTimeout(() => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 0);
  }, [messages, value]);

  let textarea = null;

  const handleClick = () => {
    if (value && messagesRef) {
      push(messagesRef, {
        text: value,
        timestamp: Date.now(),
        from: loggedInUserId,
        fromUser: true,
      });
      setValue("");
    }
    textarea?.focus();
    // console.log("fsd", inputRef.current)
  };

  const newChat = () => {
    const key = push(userRef).key;
    startChatListener(loggedInUserId, key);
    set(child(userRef, "chats/lastChatId"), key);

    textarea?.focus();
    // console.log("fsd", inputRef.current)
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleClick();
    }
  };

  const inputRef = useRef();

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <div className="chat">
      <div className="messages">
        {messages.map((message, index) =>
          message.text ? <ChatMessage message={message} index={index} /> : null
        )}
      </div>
      <TextareaAutosize
        style={{ width: "100%" }}
        value={value}
        onChange={(event) => {
          setValue(event.target.value);
        }}
        onKeyDown={handleKeyDown}
        ref={(tag) => (textarea = tag)}
      ></TextareaAutosize>
      <center>
        <button
          type="button"
          onClick={() => {
            handleClick();
          }}
        >
          Send
        </button>
        <button
          type="button"
          onClick={() => {
            newChat();
          }}
        >
          Start Over
        </button>
      </center>
      <div ref={messagesEndRef} style={{ height: 10, width: 10 }} />
    </div>
  );
}

export default Chat;
